import React, { useState, useContext, useRef } from 'react';

import { useQuery } from '@tanstack/react-query';

import authAxios from '../../components/authAxios';
import {UserContext} from "../../App"

import { TextField, Autocomplete } from "@mui/material";
import {Button} from "@mui/material";
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import DeleteIcon from '@mui/icons-material/Delete';
import IconButton from '@mui/material/IconButton';

import { Modal } from 'antd';
import NumberFormat from "react-number-format";

import '../../styles/ProductDetails.css'


const ProductDetails = ({ addTxnLine, isModalOpen, setIsModalOpen, txnLines, tag, clientProfile, location }) => {
    // const page_size = useContext(UserContext).page_size;
    // const userSettings = useContext(UserContext);
    const { pageSize } = useContext(UserContext);

    const validDataRef = useRef(false);

    const [item, setItem] = useState();
    const [sku, setSku] = useState();
    const [product, setProduct] = useState();
    const [productName, setProductName] = useState();
    const [quantity, setQuantity] = useState();
    const [heplerTextQty, setHeplerTextQty] = useState("");

    const [cost, setCost] = useState();
    const [price, setPrice] = useState();
    const [subTotal, setSubTotal] = useState();
    const tax = useRef(0)
    const total = useRef(0)
    const amt = useRef(0)
    const amtLabel = useRef("")
    const inventoryLevel = useRef(0)


    const [quantityError, setQuantityError]=useState(false)
    const [totalError, setTotalError]=useState(false)

    if (tag==="GRN"){
        amtLabel.current='Cost'
    }
    else {
        amtLabel.current='Price'
    }
    // amt.current=0


    const showModal = () => {
        setIsModalOpen(true);
    };

    // const handleOk = () => {
    // setIsModalOpen(false);
    // };

    const handleCancel = () => {
        setIsModalOpen(false);
    };

    // const [accounts, setAccounts] = useState([]);
    // const { data: accts } = useAxios({
    //     url: "/accounting/accounts-tree",
    //     method: 'get'
    // })  

    const [products, setProducts] = useState([]);
   
    const { data: taxes } = useQuery({
        queryKey: ['taxes'],
        queryFn: async () => {
            const response = await authAxios.get("/accounting/taxes");
            return response.data
        },
    });

    const { data: productTaxes } = useQuery({
        queryKey: ['productTaxes'],
        queryFn: async () => {
            const response = await authAxios.get("/products/product-taxes");
            return response.data
        },
    });

    const { data: items } = useQuery({
        queryKey: ['items'],
        queryFn: async () => {
          const response = await authAxios.get(`/inventory/items/?page_size=${pageSize}`);
          setProducts(response.data.results)
          return response.data.results;
        },
    });

    // console.log("PAGE SIZE",pageSize)


    const [productData, setProductData] = useState({
        product: '',
        sku: '',
        item: '',
        name: '',
        price: '',
        cost: '',
        quantity: '',
        subtotal: '',
        tax: '',
        total: '',
        });


    const handleSubmit = (e) => {
        e.preventDefault();
        validDataRef.current=true

        if (clientProfile.inventory_tracking && quantity>inventoryLevel.current && tag !=="GRN") {
            setQuantityError(true)
            validDataRef.current=false
        }

        if (quantity <= 0 || quantity === null || quantity === undefined) {
            setQuantityError(true)
            validDataRef.current=false
        }



        if (total <= 0 || total === null || total === undefined) {
            setTotalError(true)
            validDataRef.current=false
        }
       
        if (validDataRef.current===true) {
            const pTaxes = productTaxes.filter(tax => tax.product === product);
            for(let i= 0; i < pTaxes.length; i++){
                const ptax = taxes.find(tax => tax.id === pTaxes[i].tax);
                tax.current = tax.current + (parseFloat(subTotal) * ptax.rate)
            }

            total.current = subTotal + tax.current        

            const newProduct = {
                product: product,
                sku:sku,
                item:item,
                name:productName,
                quantity:quantity,
                cost:amt.current,
                price:amt.current,
                subtotal:subTotal,   //To be deprecated
                sub_total:subTotal,
                tax:tax.current,
                total:total.current,
            };

            addTxnLine(newProduct);

            // Reset the form and close the modal
            // amt.current=""
            setProductData({
                product: '',
                sku: '',
                item:'',
                name: '',
                price: '',
                cost: '',
                quantity: '',
                subtotal: '',
                sub_total: '',
                tax: '',
                total: '',
            });
            setIsModalOpen(false);
        }
    };

    // console.log("TXN",txnLines)
    // console.log("TAG",tag)


    return (
        <>
        <div className="product-details-container">
            <table className="styled-table">
                <thead>
                    <tr >
                        <th>SKU</th>
                        <th>Product</th>
                        {/* <th>Quantity</th> */}
                        <th>QTY</th>
                        <th>{amtLabel.current}</th>
                        <th>Total</th>
                        <th></th>
                    </tr>
                </thead>
                {txnLines && txnLines.map((product, index) => (
                    <tbody key={index}>
                        <tr>
                            <td>{product.sku}</td>
                            <td>{product.name}</td>
                            <td>{Number(product.quantity).toLocaleString()}</td>
                            {tag==="GRN"? <td>{Number(product.cost).toLocaleString()}</td> : <td>{Number(product.price).toLocaleString()}</td>}

                            <td>{Number(product.total).toLocaleString()}</td>
                            <td>
                                <IconButton 
                                    size="small">
                                    <DeleteIcon fontSize="small" />
                                </IconButton>
                            </td>
                        </tr>
                    </tbody>
                ))}
            </table>
        </div>

        <div className="AddRow">
            <Button
                id="AddRowButton"
                onClick={() => {
                    amt.current=0
                    setSubTotal("")
                    setIsModalOpen(true)}
                }
                variant="outlined" 
                size="small"
                startIcon={<AddCircleOutlineIcon />}
            >
                Product
            </Button>
        </div>
        {isModalOpen && 
            <Modal className="dashboard-modal" title="Add Product" visible={isModalOpen} open={isModalOpen} onOk={handleSubmit} onCancel={handleCancel}>
                <div className="dashboard-form">
                    <form onSubmit={handleSubmit}>
                        <Autocomplete
                            onChange={(event, newValue) => {
                                setProduct(newValue.product)
                                setProductName(newValue.name)
                                setSku(newValue.sku)
                                setItem(newValue.id)   

                                if (tag ==='GRN'){
                                    amt.current=newValue.cost
                                }

                                else {
                                    amt.current=newValue.price
                                    inventoryLevel.current = 0
                                    if (newValue.inventory_level && newValue.inventory_level.length > 0) {
                                        const levelItem = newValue.inventory_level.find(level => level.location === location);
                                        if (levelItem) {
                                            inventoryLevel.current = levelItem.quantity;
                                        } 
                                    }
                                    setHeplerTextQty("Available inventory quantity is " + inventoryLevel.current)

                                }
                                }}
                            options={products}
                            getOptionLabel={(option) => option.name}
                            renderInput={(params) => 
                                <TextField 
                                    className='custom-input'
                                    label="Product" 
                                    {...params} 
                                />
                            }
                        />
                        <NumberFormat 
                            onChange={(e) => {
                                // setQty(e.target.value)
                                const qty = parseFloat(e.target.value.replace(/,/g, ''))
                                // console.log("QTY", qty)
                                // console.log("TRACKING",clientProfile.inventory_tracking)
                                // console.log("LEVEL",inventoryLevel.current)
                                // console.log("TAG",tag)

                                // if ((qty>0 && !clientProfile.inventory_tracking)||(qty<=inventoryLevel.current && clientProfile.inventory_tracking && )){
                                if (clientProfile.inventory_tracking){
                                    if ((qty>0 && tag==="GRN")||(clientProfile.inventory_tracking && qty>0 && qty<=inventoryLevel.current)){
                                        setQuantityError(false)
                                        setQuantity(qty)
                                        setSubTotal(qty*amt.current)
                                    }
                                    else{
                                        setQuantityError(true)
                                    }
                                }
                                else{
                                    if (qty>0){
                                        setQuantityError(false)
                                        setQuantity(qty)
                                        setSubTotal(qty*amt.current)
                                    }
                                    else{
                                        setQuantityError(true)
                                    }
                                }
                                
                            }}
                            className="custom-input"
                            fullWidth
                            label="Quantity" 
                            variant="outlined" 
                            customInput={TextField}
                            thousandSeparator={true} 
                            required
                            error={quantityError}
                            helperText={heplerTextQty}
                        />

                        <NumberFormat 
                            value={amt.current>0?amt.current:""}
                            onChange={(e) => {
                                // const amt = parseFloat(e.target.value.replace(/,/g, ''))
                                amt.current = parseFloat(e.target.value.replace(/,/g, ''))

                                if (tag==='GRN'){
                                    setCost(amt.current)
                                }
                                if (tag==='INV'||tag==='ORD'){
                                    setPrice(amt.current)

                                }
                                if (quantity){
                                    setSubTotal(quantity*amt.current)
                                }
                                
                            }} 
                            className="custom-input"
                            fullWidth
                            label={tag==='GRN'? 'Cost':'Price'} 
                            variant="outlined" 
                            customInput={TextField}
                            thousandSeparator={true} 
                            required
                        />

                        <NumberFormat 
                            className="custom-input"
                            fullWidth
                            label="Total" 
                            variant="outlined" 
                            customInput={TextField}
                            thousandSeparator={true} 
                            value={subTotal}
                            disabled
                            error={totalError}
                        />

                    </form>
                </div>
            </Modal>
        }
        </>

    );
};

export default ProductDetails;
